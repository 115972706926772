import {css, html, LitElement} from "lit";
import {pxToRem} from "../styles";

const validTypes = ["submit", "clear"]
const validVariants = ["primary", "secondary"]

const DEFAULT_BORDER = 0;
const DEFAULT_FONT_WEIGHT = 700;
const DEFAULT_BORDER_RADIUS = pxToRem(4);

export class WlButton extends LitElement {
    private type: string
    private variant: string

    constructor() {
        super();
        this.type = "submit"
        this.variant = "primary"
    }

    connectedCallback() {
        super.connectedCallback();
        if(!validTypes.includes(this.type)) {
            this.type = "submit"
        }
        if(!validVariants.includes(this.variant)) {
            this.variant = "primary"
        }
    }

    static get properties() {
        return {
            type: {type: String},
        };
    }

    static get styles() {
        return css`
            :host {
                flex: 1 1 0;
            }

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: var(--wl-button-padding, revert);
                border: var(--wl-button-primary-border, ${DEFAULT_BORDER});
                color: var(--wl-button-color, white);
                background-color: var(--wl-button-background-color, grey);
                cursor: pointer;
                width: var(--wl-button-width, 100%);
                height: var(--wl-button-height, ${pxToRem(35)});
                vertical-align: middle;
                border-radius: var(--wl-primary-button-border-radius, ${DEFAULT_BORDER_RADIUS});
                font-weight: var(--wl-button-primary-font-weight, ${DEFAULT_FONT_WEIGHT});
                font-size: var(--wl-button-font-size, revert);
            }
            
            :host([variant="secondary"]) button {
                border: var(--wl-button-secondary-border, var(--wl-primary-button-border, ${DEFAULT_BORDER}));
                border-radius: var(--wl-button-secondary-border-radius, var(--wl-primary-button-border-radius, ${DEFAULT_BORDER_RADIUS}));
                font-weight: var(--wl-button-secondary-font-weight, var(--wl-button-primary-font-weight, ${DEFAULT_FONT_WEIGHT}));
                color: var(--wl-button-secondary-color, white);
                background-color: var(--wl-button-secondary-background-color, grey);
            }

            button:hover {
                filter: brightness(85%);
            }
        `
    }

    render() {
        return html`
            <button type="button" @click="${this.clicked}">
                <slot></slot>
            </button>
        `
    }

    private clicked() {
        let customEvent = "wl-" + this.type;
        this.dispatchEvent(new CustomEvent(customEvent, {bubbles: true, composed: true}))
    }

}
