import {css, html, LitElement, nothing} from "lit";
import {pxToRem} from "../styles";
import {WlSelected} from "../events/WlSelected";
import {WlCheckbox} from "../checkbox/checkbox.component";
import {styleMap} from "lit/directives/style-map.js";

const DEFAULT_GAP = 16;

export class WlCheckboxGroup extends LitElement {
    protected name: string;
    value: string[];
    protected label: string[];
    private columns?: number|undefined;

    static formAssociated = true;

    // @ts-ignore
    private _internals: ElementInternals & IElementInternals;

    static get properties() {
        return {
            value: {type: String, attribute: false},
            name: {type: String},
            columns: {type: Number, reflect: true},
        };
    }

    constructor() {
        super()
        this._internals = this.attachInternals();
        this.name = ""
        this.value = []
        this.label = []
    }

    static get styles() {
        return css`
            :host > div {
                gap: var(--wl-checkbox-group-gap, ${pxToRem(DEFAULT_GAP)});
            }
        `
    }



    render() {
        return html`
            <div style="${styleMap(this.containerStyles(this.columns))}">
                <slot @wl-checked="${this.updateValue}"></slot>
            </div>
        `
    }

    firstUpdated() {
        setTimeout(() => {
            this.updateValue()
        }, 0);
    }

    updateValue() {
        const children = this.getChildren()
        const checkedChildren = children
            .filter(child => child.checked);
        const newValue = checkedChildren
            .map(child => (child.value))
            .flat()
        const newLabel = checkedChildren
            .map(child => (child.label()))
            .flat()

        if (this.areTheSame(this.value, newValue) && this.areTheSame(this.label, newLabel)) {
            return
        }
        this.value = newValue
        this.label = newLabel

        this._internals.setFormValue(this.value.join(","));
        this.dispatchEvent(new WlSelected(this.label.join(", "), this.value.join(",")))
    }

    clear() {
        this.getChildren().forEach(child => child.unCheck())
        this.updateValue();
    }

    private areTheSame(array1: string[], array2: string[]) {
        return array1.length == array2.length && array1.every(v => array2.includes(v));
    }

    private getChildren() {
        return [...this.childNodes]
            .filter(node => node.nodeType === node.ELEMENT_NODE)
            .map(node => node as WlCheckbox)
    }

    private containerStyles(columns: number|undefined) {
        return columns
            ? {display: 'grid', gridTemplateColumns: `repeat(${columns}, 1fr)`}
            : {display: 'flex', flexWrap: 'wrap'};
    }
}