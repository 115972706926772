import {css, html, LitElement} from "lit";

export class WlDropDownButtons extends LitElement {
    static get styles() {
        return css`
            :host {
                display: flex;
                flex-direction: row;
                gap: 10px;
                padding: 10px;
                width: 100%;
                height: 55px;
            }
        `
    }

    connectedCallback() {
        super.connectedCallback();
        this.slot = "buttons"
    }

    render() {
        return html`
            <slot></slot>
        `
    }
}