import {css, html, LitElement, nothing} from "lit";
import {pxToRem} from "../styles";
import {PropertyValues} from "@lit/reactive-element";
import {WlSelected} from "../events/WlSelected";
import {WlTree} from "../tree/tree";
import {WlSubmit} from "../events/WlSubmit";
import {WlRange} from "../range/range.component";
import {WlCheckboxGroup} from "../checkboxGroup/checkboxGroup.component";
import {WlMultipleComponents} from "../multipleComponents/multipleComponents.component";
import {WlMultiLayer} from "../layerSelector/layers.component";

export class WlDropdown extends LitElement {
    static formAssociated = true;

    private _internals: ElementInternals;

    protected placeholder: string;
    protected label: string;
    protected name: string;
    value: string;
    protected header: string;
    protected open: boolean;
    protected stayOpenOnSelect: boolean;

    static get styles() {
        return [css `
            :host {
                display: block;
                position: relative;
                font-size: var(--wl-control-font-size, ${pxToRem(18)});
            }
            
            :host([open]) .button {
                border-color: var(--wl-control-opened-border-color, #2A355A);
            }
            
            ::slotted(wl-checkbox-group) {
                display: block;
                padding: var(--wl-checkbox-group-padding, ${pxToRem(12)});
            }
            
            .title {
                display: flex;
                font-style: normal;
                font-weight: 700;
                font-size: ${pxToRem(14)};
                line-height: ${pxToRem(19)};
                color: var(--wl-header-color, #0A0A0A);
                padding-bottom: var(--wl-header-padding-bottom, ${pxToRem(10)});
            }

            .button-container {
                max-width: var(--wl-control-width, auto);
                width: var(--wl-control-width, auto);
                min-width: var(--wl-control-width, auto);
            }

            .button {
                display: flex;
                align-items: center;
                cursor: pointer;
                border: 1px solid var(--wl-control-border-color, #8F939F);
                border-radius: var(--wl-control-border-radius, ${pxToRem(4)});
                height: var(--wl-control-height, ${pxToRem(41)});
                position: relative;
                padding:0 ${pxToRem(10)};
                font-size:var(--wl-control-font-size, ${pxToRem(14)});
                color:var(--wl-control-font-color, initial);
                font-weight: var(--wl-control-font-weight, initial);
            }

            .label {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .button.disabled {
                cursor: auto;
                opacity: 50%;
                pointer-events: none;
            }

            .button span {
                flex: 1;
            }
            
            .button i {
                width: 16px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .button i:after {
                content: "";
                display: block;
                box-sizing: border-box;
                width: ${pxToRem(8)};
                height: ${pxToRem(8)};

                border-bottom: ${pxToRem(2)} solid;
                border-right: ${pxToRem(2)} solid;
                transform: rotate(45deg);
                color: $${pxToRem(15)};
            }

            :host([open]) .button i:after {
                transform: rotate(225deg);
                top: ${pxToRem(20)};
            }


            :host([open]) .content {
                display: flex;
                flex-direction: column;
            }

            .content {
                display: none;
                border: var(--wl-control-border-size, ${pxToRem(1)}) solid var(--wl-dropdown-content-border-color, var(--wl-control-border-color, #8F939F));
                border-radius: var(--wl-control-border-radius, ${pxToRem(4)});
                max-height: var(--wl-dropdown-content-height, ${pxToRem(275)});
                position: absolute;

                top: calc(var(--wl-control-height) + var(--wl-control-border-size, ${pxToRem(1)}) + var(--wl-control-content-gap, ${pxToRem(10)}));
                right: var(--wl-dropdown-content-right, initial);
                z-index: 5;
                background-color: white;
                width: var(--wl-control-content-width, ${pxToRem(100)});
                max-width: var(--wl-control-content-width, ${pxToRem(100)});
                overflow-y: auto;
                box-shadow: var(--wl-dropdown-content-shadow, none);
            }

            .scrollable-content {
                height: fit-content;
                overflow-y: auto;
                overflow-x: hidden;
            }

            :host([header]) .content {
                top: calc(var(--wl-control-height) + ${pxToRem(35)});
            }


            slot[name="buttons"] {
                justify-content: flex-end;
            }
        `]
    }

    static get properties() {
        return {
            placeholder: {type: String},
            value: {type: String},
            name: {type: String},
            label: {type: String},
            open: {type: Boolean, reflect: true},
            header: {type: String},
            stayOpenOnSelect: {type: Boolean, attribute: "stay-open-on-select"}
        };
    }

    constructor() {
        super()
        // @ts-ignore
        this._internals = this.attachInternals();
        this.label = ""
        this.placeholder = ""
        this.value = ""
        this.name = ""
        this.header = ""
        this.open = false
        this.stayOpenOnSelect = false;
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener("click", this._handleClickOutside.bind(this));
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('click', this._handleClickOutside.bind(this));
    }

    private _handleClickOutside(event: Event) {
        const clickOutside = !event.composedPath().includes(this)
        if (clickOutside) {
            this._close()
        }
    }

    render() {
        return html`
            ${this._renderTitle()}
            <div class="button-container" @click="${this._toggle}">
                <div class="button">
                    <span class="label">${this.label === "" ? this.placeholder : this.label}</span>
                    <i></i>
                </div>
            </div>
            <div class="content" data-testid="content">
                <div class="scrollable-content">
                    <slot @wl-selected="${this._optionSelected}"></slot>
                </div>
                <slot name="buttons" @wl-clear="${this._clear}" @wl-submit="${this._submit}"></slot>
            </div>
        `
    }

    private _clear(event: CustomEvent) {
        (this.shadowRoot!.querySelector("slot:not([name])") as HTMLSlotElement)
            .assignedElements()
            .filter(child => child instanceof WlTree)
            .map(child => child as WlTree)
            .forEach(child => child.clear());
        (this.shadowRoot!.querySelector("slot:not([name])") as HTMLSlotElement)
            .assignedElements()
            .filter(child => child instanceof WlRange)
            .map(child => child as WlRange)
            .forEach(child => child.clear());
        (this.shadowRoot!.querySelector("slot:not([name])") as HTMLSlotElement)
            .assignedElements()
            .filter(child => child instanceof WlCheckboxGroup)
            .map(child => child as WlCheckboxGroup)
            .forEach(child => child.clear());
        (this.shadowRoot!.querySelector("slot:not([name])") as HTMLSlotElement)
            .assignedElements()
            .filter(child => child instanceof WlMultipleComponents)
            .map(child => child as WlMultipleComponents)
            .forEach(child => child.clear());
        (this.shadowRoot!.querySelector("slot:not([name])") as HTMLSlotElement)
            .assignedElements()
            .filter(child => child instanceof WlMultiLayer)
            .map(child => child as WlMultiLayer)
            .forEach(child => child.clear());
        this.value = "";
        this._internals.setFormValue(this.value);
    }

    private _submit(event: CustomEvent) {
        this.changeFormValue();
    }

    firstUpdated(args: PropertyValues) {
        super.firstUpdated(args);
        this._internals.setFormValue(this.value);
    }

    protected _renderTitle() {
        if (this.header === "") {
            return nothing
        }
        return html`<span class="title">${this.header}</span>`
    }

    protected _optionSelected(event: WlSelected) {
        event.stopPropagation()
        this._changeValue(event.text, event.value)
    }

    protected _changeValue(label: string, value: string) {
        this.label = label
        this.value = value
        this._internals.setFormValue(this.value);
        this.changeFormValue()
    }

    private changeFormValue() {
        if (this.stayOpenOnSelect) {
            return
        }
        this._close()
        this.dispatchEvent(new WlSubmit())
    }

    protected _toggle() {
        this.open = !this.open
    }

    _close() {
        this.open = false
    }

    protected _open() {
        this.open = true;
    }
}
