import {html, LitElement} from "lit";
import {WlSelected} from "../events/WlSelected";
import {WlOption} from "../option/option.component";
import {PropertyValues} from "@lit/reactive-element";

export class WlMonoSelect extends LitElement {
    protected value: string = ""

    render() {
        return html`
            <slot @wl-selected="${this._maintainOneSelected}" @firstchange="${this.firstUpdated}"></slot>
        `
    }

    protected firstUpdated(_changedProperties: PropertyValues) {
        setTimeout(() => {
            this.updateValue()
        }, 0);
    }

    updateValue() {
        let childSelected = this.getChildSelected();
        if (childSelected) {
            this.dispatchSelectedEvent(childSelected);
        }
    }

    private dispatchSelectedEvent(childSelected: WlOption) {
        const newValue = childSelected.getValue()
        if (this.value == newValue) {
            return
        }
        this.value = newValue
        this.dispatchEvent(new WlSelected(this.getText(childSelected), this.value))
    }

    private getText(childSelected: WlOption) {
        if (this.value == '') {
            return ''
        } else {
            return childSelected.getText();
        }
    }

    private getChildSelected() {
        return this.getChildren()
            .filter(child => child.isChecked())[0];
    }

    private getChildren() {
        return [...this.childNodes]
            .filter(node => node.nodeType === node.ELEMENT_NODE)
            .map(node => node as WlOption)
    }

    _maintainOneSelected(event: WlSelected) {
        event.stopPropagation()
        const slot = this.shadowRoot?.querySelector('slot');
        slot?.assignedElements({flatten: true})
            .filter((element) => element != event.target)
            .forEach((element) => (element as WlOption).uncheck());
        this.dispatchSelectedEvent(event.target as WlOption)
    }

    clear() {
        const slot = this.shadowRoot?.querySelector('slot');
        slot?.assignedElements({flatten: true})
            .forEach((element) => (element as WlOption).uncheck());
    }
}