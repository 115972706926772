import {css, html, LitElement} from "lit";
import {pxToRem} from "../styles";

export class WlModalFieldButtons extends LitElement {
    static get styles() {
        return css`
            :host {
                display: flex;
                flex-direction: row;
                gap: ${pxToRem(10)};
                padding: ${pxToRem(10)};
                width: 100%;
                height: ${pxToRem(55)};
                margin-top: auto;
            }
        `
    }

    connectedCallback() {
        super.connectedCallback();
        this.slot = "buttons"
    }

    render() {
        return html`
            <slot></slot>
        `
    }
}
