import {LitElement} from "lit";

type Constructor<T> = new (...args: any[]) => T;

export declare class ValuableInterface {
    label: string
    value: string | FormData | null
    clear() : void
    setValue(getValue: () => string | FormData | null, getLabel: () => string): void
    disabled: boolean
}

export const Valuable = <T extends Constructor<LitElement>>(superClass: T) => {
    class ValuedComponent extends superClass {
        public label: string = "";
        public value: string | FormData | null = null;
        public disabled: boolean = false;

        clear() {
            this.value = null;
            this.label = "";
            console.log("clear", this)
            this.dispatchEvent(new CustomEvent("wl-clear", {bubbles: true, composed: true}));
            this.dispatchEvent(new CustomEvent("wl-change", {bubbles: true, composed: true}));
        }

        setValue(getValue: () => string | FormData | null, getLabel: () => string): void {
            this.value = getValue();
            this.label = getLabel();
        }

    }
    return ValuedComponent as Constructor<ValuableInterface> & T;

}