
export class WlSelected extends Event {
    public text: string
    public value: string

    constructor(text: string, value: string) {
        super('wl-selected', {bubbles: true, composed: true});
        this.text = text
        this.value = value
    }
}