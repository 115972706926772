import {css, html, LitElement} from "lit";
import {pxToRem} from "../styles";
import {WlSelected} from "../events/WlSelected";
import {WlRange} from "../range/range.component";
import {WlMultiLayer} from "../layerSelector/layers.component";

export class WlMultipleComponents extends LitElement {

    constructor() {
        super();
    }

    static get styles() {
        return css`
            :host {
                display: flex;
                flex-direction: column;
                gap: ${pxToRem(16)};
                padding: var(--wl-multiple-component-padding, ${pxToRem(10)});
            }
        `
    }

    render() {
        return html`
            <slot @wl-selected="${this.selectLabelToShow}" @wl-change="${this.selectLabelToShow}"></slot>
        `
    }

    clear() {
        this.getChildren().forEach(child => this.childClear(child))
    }

    private selectLabelToShow(event: WlSelected) {
        event.stopPropagation();
        let children = this.getChildren()
        let childrenWithValueOrdered = children
            .filter(child => this.getLabelOf(child) != '')
            .sort((n1, n2) => this.getPriorityOf(n1) - this.getPriorityOf(n2));
        if (childrenWithValueOrdered.length > 0) {
            let first = childrenWithValueOrdered[0];
            this.dispatchEvent(new WlSelected(this.getLabelOf(first), this.getLabelOf(first)));
        } else {
            this.dispatchEvent(new WlSelected('', ''));
        }
    }

    private getLabelOf(child: any) {
        if (child instanceof WlRange) {
            return (child as WlRange).label;
        } else if (child instanceof WlMultiLayer) {
            return (child as WlMultiLayer).label
        }
        return "";
    }

    private getPriorityOf(child: any): number {
        if (child instanceof WlRange) {
            return parseInt((child as WlRange).priority);
        } else if (child instanceof WlMultiLayer) {
            return parseInt((child as WlMultiLayer).priority)
        }
        return Number.MAX_VALUE;
    }

    private getChildren() {
        return [...this.childNodes]
            .filter(node => node.nodeType === node.ELEMENT_NODE)
    }

    private childClear(child: any): void {
        if (child instanceof WlRange) {
            (child as WlRange).clear();
        } else if (child instanceof WlMultiLayer) {
            (child as WlMultiLayer).clear();
        }
    }
}
