import {css, html, LitElement} from "lit";
import {pxToRem} from "../styles";
import {WlSelected} from "../events/WlSelected";

export class WlOption extends LitElement {
    protected value: string
    protected text: string
    protected checked: boolean

    static get properties() {
        return {
            value: {type: String},
            text: {type: String},
            checked: {type: Boolean, reflect: true},
        };
    }

    constructor() {
        super();
        this.value = "";
        this.text = "";
        this.checked = false;
    }

    static get styles() {
        return css`
            :host {
                display: block;
                margin: 0;
                cursor: pointer;
                font-size: var(--wl-control-font-size, ${pxToRem(14)});
                color: var(--wl-color, #3E495F);
            }

            :host(:hover) {
                background-color: var(--wl-hover-background, #2A355A);
                color: var(--wl-hover-color, #FFF);
            }

            :host([checked]) {
                background-color: var(--wl-selected-background, #2A355A);
                color: var(--wl-selected-color, #FFF);
            }

            .label {
                padding: ${pxToRem(10)} ${pxToRem(9)};
                cursor: pointer;
                width: 100%;
                display: flex;
                align-items: center;
                margin: 0;
            }

            input {
                display: none;
                width: ${pxToRem(15)};
                height: ${pxToRem(15)};
                margin-right: ${pxToRem(8)};
                cursor: pointer;
                accent-color: var(--wl-selected-color, #FFF);
            }
        }`
    }

    render() {
        return html`
            <p class="label" @click="${this._check}">${this.text}</p>
        `
    }

    _check() {
        this.checked = true;
        this.dispatchEvent(new WlSelected(this.text, this.value))
    }

    uncheck() {
        this.checked = false;
    }

    getValue() {
        return this.value;
    }

    isChecked() {
        return this.checked;
    }

    getText() {
        return this.text;
    }
}